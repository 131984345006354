/* ----------------------------------------------------------------------------------------------------- */
/*  @ Example viewer
/* ----------------------------------------------------------------------------------------------------- */
.example-viewer {
    display: flex;
    flex-direction: column;
    margin: 32px 0;
    border-radius: 4px;
    overflow: hidden;
    @apply shadow bg-card;

    .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 88px;
        min-height: 88px;
        max-height: 88px;
        padding: 0 40px;

        h6 {
            font-weight: 700;
        }

        .controls {
            display: flex;
            align-items: center;

            > * + * {
                margin-left: 8px;
            }
        }
    }

    mat-tab-group {

        .mat-tab-header {
            @apply bg-gray-50 border-t;

            .dark & {
                @apply bg-card;
            }
        }

        .mat-tab-body-content {
            padding: 40px;

            .cipafacil-highlight {
                margin: -40px;

                pre {
                    margin: 0;
                    border-radius: 0;
                }
            }
        }
    }
}
